<template>
  <v-layout wrap>
    <v-flex
      xs12
    >
      <material-app-card
        class="wbs-list"
        :color="section_color"
        icon="mdi-text-box"
        title="My WBS Requests"
      >
        <template v-slot:extra>
          <v-tooltip left :disabled="$vuetify.breakpoint.smAndUp || isMobile">
            <template v-slot:activator="{ on }">
              <v-btn 
                v-if="!isMobile"
                v-on="on"
                text
                small
                class="before-workflow-list-btn-new extra-button-text"
                :color="section_color"
                :icon="!$vuetify.breakpoint.smAndUp"
                @click="addWbs"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
                <span 
                  style="font-size:14px;padding-left:8px;font-weight:bold"
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  Create New WBS
                </span>
              </v-btn>
            </template>
            <span>Create New WBS</span>
          </v-tooltip>
        </template>
        <workflow-list
          class="approval-workflows"
          table-id="my-contract"
          type="wbs"
          :color="section_color"
          @click-item="reviewWbs"
          @auth-fail="logoff"></workflow-list>
      </material-app-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { setTimeout } from 'timers';

import { section_color } from "@/config";
import WorkflowList from "@/components/app/workflow-list-v3";


export default {
  components: {
    WorkflowList
  },
  computed: {
    ...mapGetters('app', [
      'isMobile'
    ]),
    section_color() {
      return section_color['wbs']
    }
  },
  methods: {
    ...mapActions('app', ['setMainTitle']),
    
    addWbs() {
      try {
        this.$router.push({path: '/ci/wbs/details/new'})
      } catch (err) {
        console.log('Error [add wbs review]: ')
        // console.log(err)
      }
    },
    reviewWbs(item) {
      try {
        switch (item.item.requestType) {        
          case "WBS Request":
            this.$router.push({path: `/ci/wbs/details/${item.item.token}`})
            break;
        
          default:
            break;
        }
      } catch (err) {
        console.log('Error [review wbs]: ')
        // console.log(err)
      }
    },
    logoff() {
      this.$router.push({path: '/login'})
    }
  },
  created () {
    this.setMainTitle('CI WBS')
  }
}
</script>

<style lang="sass">
  .wbs-list
    .extra-button-text
      padding: 8px 14px 8px 8px !important
    

    .requestor-workflows
      margin-top: -32px
    
  
</style>
